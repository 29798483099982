import { ChainId } from '@pyeclaim/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}

export const getOldPYEAddress = () => {
  return getAddress(addresses.oldPYE)
}
export const getOldAppleAddress = () => {
  return getAddress(addresses.oldApple)
}
export const getOldCherryAddress = () => {
  return getAddress(addresses.oldCherry)
}
export const getOldForceAddress = () => {
  return getAddress(addresses.oldForce)
}
export const getPYEAddress = () => {
  return getAddress(addresses.pye)
}
export const getAppleAddress = () => {
  return getAddress(addresses.apple)
}
export const getAppleStakingAddress = () => {
  return getAddress(addresses.appleStaking)
}
export const getCherryAddress = () => {
  return getAddress(addresses.cherry)
}
export const getMigrationAddress= () => {
  return getAddress(addresses.migration)
}
